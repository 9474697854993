export const Bio = {
  name: "Sanjay Kumar",
  roles: [
    "UI/UX Designer",
    "Frontend Developer",
    "Product Designer",
    "SDE Developer",
  ],
  description:
  "Frontend Developer | Full Stack Developer | Data Structures & Algorithms Enthusiast 📊💻 | Building seamless and responsive digital experiences 🌐✨ | Passionate about solving problems through clean code and innovative solutions 💡🚀"
    ,
  github: "https://www.behance.net/attellisanjay/",
  resume:
    "https://drive.google.com/file/d/1a9qmFQkipcN1rmk2dxOGDLMFZAm9xRx1/view?usp=sharing",
    
  linkedin: "https://www.linkedin.com/in/attelli-sanjay-kumar/",
  twitter: "https://twitter.com/SanjayAttelli",
  insta: "https://www.instagram.com/editwithsanjay/",
  facebook: "https://www.facebook.com/share/xExSYQfQKuTMcZ6i/?mibextid=qi2Omg",
};

export const skills = [
  {
    title: "Designing",
    skills: [
      {
        name: "Adobe XD",
        image:
          "https://gdm-catalog-fmapi-prod.imgix.net/ProductLogo/22b74bf9-830b-4bad-88ee-871368b6f6a3.png?w=128&h=128&fit=max&dpr=3&auto=format&q=50",
      },
      {
        name: "Sketch",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/Sketch_Logo.svg/1133px-Sketch_Logo.svg.png",
      },
      {
        name: "InVision Studio",
        image:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6tpzQW-UHflig48IIvO2E5b0r54jiJYuChkvkOVwB6NpiYztZf8tJp9WxC8NfeB25YWk&usqp=CAU",
      },
      {
        name: "Axure RP",
        image:
          "https://yt3.googleusercontent.com/ytc/AIdro_nLLyKFYTB-8k9Kco4zhpyMIYqRmTd3jh_RZ7wGqIY1ZJo=s900-c-k-c0x00ffffff-no-rj",
      },
      {
        name: "Framer",
        image: "https://miro.medium.com/v2/resize:fit:2400/1*F6ufPKgGbyUPkWLZ-16ycw.png ",
      },
      {
        name: "Marvel App",
        image:
          "https://cdn.icon-icons.com/icons2/2429/PNG/512/marvel_logo_icon_147266.png",
      },
      {
        name: "Adobe Illustrator",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Adobe_Illustrator_CC_icon.svg/180px-Adobe_Illustrator_CC_icon.svg.png",
      },
      {
        name: "Origami Studio",
        image:
          "https://w7.pngwing.com/pngs/652/660/png-transparent-origami-studio-round-logo-tech-companies-thumbnail.png",
      },
    ],
  },
  {
    title: "Languages",
    skills: [
      {
        name: "Python",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
      },
      {
        name: "Tenserflow",
        image:
          "https://static-00.iconduck.com/assets.00/tensorflow-icon-1911x2048-1m2s54vn.png",
      },
      {
        name: "Html",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/HTML5_Badge.svg/2048px-HTML5_Badge.svg.png",
      },
      {
        name: "CSS",
        image:
          "https://cdn.iconscout.com/icon/free/png-256/free-css3-logo-icon-download-in-svg-png-gif-file-formats--css-programming-langugae-language-pack-logos-icons-1175237.png",
      },
      {
        name: "Jupyter",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/Jupyter_logo.svg/1767px-Jupyter_logo.svg.png",
      },
      {
        name: "Webflow ",
        image:
          "https://cdn.prod.website-files.com/5f15081919fdf673994ab5fd/651f24a499bc8356ba844e0c_Webflow-Logo.svg",
      },
      {
        name: "Google Colab",
        image:
          "https://mikaelahonen.com/img/google-colab.png",
      },
      {
        name: "JavaScript",
        image:
          "https://static.vecteezy.com/system/resources/previews/027/127/463/non_2x/javascript-logo-javascript-icon-transparent-free-png.png",
      },
      {
        name: "ReactJS",
        image:
          "https://cdn.iconscout.com/icon/free/png-256/free-react-logo-icon-download-in-svg-png-gif-file-formats--company-brand-world-logos-vol-4-pack-icons-282599.png?f=webp&w=256",
      },
      {
        name: "C++",
        image:
          "https://e7.pngegg.com/pngimages/46/626/png-clipart-c-logo-the-c-programming-language-computer-icons-computer-programming-source-code-programming-miscellaneous-template.png",
      },
    ],
  },
  {
    title: "Frameworks",
    skills: [
      {
        name: "AWS",
        image:
          "https://download.logo.wine/logo/Amazon_Web_Services/Amazon_Web_Services-Logo.wine.png",
      },
      {
        name: "Google Cloud",
        image:
          "https://static-00.iconduck.com/assets.00/google-cloud-platform-logo-icon-2048x1824-pg4wzspq.png",
      },
      {
        name: "Docker",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/docker/docker-original-wordmark.svg",
      },
      {
        name: "Jenkins",
        image:
          "https://toppng.com/uploads/preview/jenkins-logo-11609365847mufysaivph.png",
      },
      {
        name: "Nginx",
        image: "https://download.logo.wine/logo/Nginx/Nginx-Logo.wine.png",
      },
      {
        name: "Grafana",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a1/Grafana_logo.svg/1200px-Grafana_logo.svg.png",
      },
      {
        name: "Kubernetes",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/0/00/Kubernetes_%28container_engine%29.png",
      },
      {
        name: "Prometheus",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/Prometheus_software_logo.svg/1200px-Prometheus_software_logo.svg.png",
      },
    ],
  },
  {
    title: "UI/UX Design Principles",
    skills: [
      {
        name: "Lucidchart",
        image:
          "https://downloadr2.apkmirror.com/wp-content/uploads/2022/02/54/61fc68d2b6740.png",
      },
      {
        name: " Interaction Design",
        image:
          "https://www.vectorlogo.zone/logos/kotlinlang/kotlinlang-icon.svg",
      },
      {
        name: "Mural",
        image:
          "https://cdn.prod.website-files.com/62e11362da2667ac3d0e6ed5/659d7f9e582a15e81030a3cf_Mural_Symbol_Multicolor_RGB.png",
      },
      {
        name: "Whimsical",
        image:
          "https://i.pinimg.com/originals/ea/2a/b2/ea2ab287b40acfe28348c71eb780d11c.png",
      },
      {
        name: "Miro",
        image:
          "https://pbs.twimg.com/profile_images/1699412547659595776/VS0PZmJN_400x400.jpg",
      },
      {
        name: "FlowMapp ",
        image:
          "https://asset.brandfetch.io/idZNed_6aS/idzZy6xLWv.jpeg",
      },
      {
        name: "protopie",
        image:
          "https://seeklogo.com/images/P/protopie-logo-A51D8D3BEE-seeklogo.com.png",
      },
    ],
  },
 
  {
    title: "Others",
    skills: [
      {
        name: "Git",
        image:
          "https://e7.pngegg.com/pngimages/713/558/png-clipart-computer-icons-pro-git-github-logo-text-logo-thumbnail.png",
      },
      {
        name: "GitHub",
        image:
          "https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png",
      },
      {
        name: "Netlify",
        image:
          "https://seeklogo.com/images/N/netlify-logo-BD8F8A77E2-seeklogo.com.png",
      },
      {
        name: "VS Code",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/512px-Visual_Studio_Code_1.35_icon.svg.png?20210804221519",
      },
      {
        name: "Postman",
        image:
          "https://static-00.iconduck.com/assets.00/postman-icon-497x512-beb7sy75.png",
      },
      {
        name: "GeeksforGeeks",
        image:
          "https://media.geeksforgeeks.org/gfg-gg-logo.svg",
      },
      {
        name: "Figma",
        image:
          "https://s3-alpha.figma.com/hub/file/1481185752/fa4cd070-6a79-4e1b-b079-8b9b76408595-cover.png",
      },
    ],
  },
  {
    title: "Editing ",
    skills: [
      {
        name: "Premiere Pro",
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/40/Adobe_Premiere_Pro_CC_icon.svg/180px-Adobe_Premiere_Pro_CC_icon.svg.png",
      },
      {
        name: "Final Cut Pro",
        image: "https://upload.wikimedia.org/wikipedia/en/thumb/9/9f/2015_Final_Cut_Pro_Logo.png/180px-2015_Final_Cut_Pro_Logo.png",
      },
      {
        name: "After Effects",
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Adobe_After_Effects_CC_icon.svg/180px-Adobe_After_Effects_CC_icon.svg.png",
      },
      {
        name: "DaVinci Resolve",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4d/DaVinci_Resolve_Studio.png/180px-DaVinci_Resolve_Studio.png",
      },
      
      {
        name: "Filmora",
        image: "https://downloadr2.apkmirror.com/wp-content/uploads/2023/02/21/63ecaf917e62d.png",
      },
      {
        name: "HitFilm Express",
        image:
          "https://img.icons8.com/?size=512&id=gzPYPsH3mwvu&format=png",
      },
    ],
  },
  
  
];

export const experiences = [
  {
    id: 0,
    img: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/flipr.jpeg?alt=media&token=1d72532a-45eb-4c1a-a81a-c9bed9fec543",
    role: "Frontend Web Developer",
    company: "Flipr",
    date: "September 2024 - Present",
    desc: "Flipr is a creative studio that specializes in Frontend Development and Data Structures (DS), transforming ideas into dynamic and responsive web applications while building efficient algorithms. We focus on delivering seamless user experiences backed by optimized data management.",
    skills: [
      "Web Design",
      "HTML/CSS",
      "React.js / Angular.js",
      "User Interface Design",
      "Figma (Software)",
      "JavaScript",
    ],
    refLink:"https://flipr.ai/"
    /*doc: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/Screenshot%20from%202024-01-25%2022-38-31.png?alt=media&token=2785903f-1a4e-41f5-afd2-6adcfe56d058",*/
  },
  {
    id: 1,
    img: "https://media.licdn.com/dms/image/v2/D560BAQGeowC4xiIPkw/company-logo_200_200/company-logo_200_200/0/1690720091257/jacinthpaulbusinessteam_logo?e=1733356800&v=beta&t=fOT6OqEklU8X_fjxlzJP3OpEjqMpjEYUzerLtiiJdoE",
    role: "Frontend Developer Intern",
    company: "Jacinth Paul Business Technologies Private Limited",
    date: "June 2024 - August 2024",
    desc: "Jacinth Paul Business Technologies Private Limited is a leader in Frontend Development and Data Structures, delivering cutting-edge digital solutions that combine creative design with technical excellence. We specialize in building responsive interfaces.",
    skills: [
      "HTML/CSS",
      "User Interface Design",
      "JavaScript Frameworks (React, Vue)",
      "Graphic Design",
      "Algorithm Optimization",
      "User Experience (UX)",
      "Frontend Performance Optimization",
    ],
    refLink:"https://business.jacinthpaul.com/",
    doc: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/1696514649120.jpeg?alt=media&token=e7f6757b-edfa-4138-a692-d6709eeef3e2",
  },
  {
    id: 2,
    img: "https://media.licdn.com/dms/image/v2/D560BAQH1HYW0lD9NJQ/company-logo_200_200/company-logo_200_200/0/1706942233854/aspireup_ai_logo?e=1733356800&v=beta&t=AELAHU3BEsemFJ4yMOH3V5MuWCx0ujHpUby6ernJs4M",
    role: "User Interface Designer",
    company: "Aspireup ",
    date: "June 2024 - August 2024",
    desc: "Aspireup is a dynamic and innovative company dedicated to delivering exceptional results in UI/UX design, graphic design, product design, and video editing. Our commitment to quality and creativity drives us to push boundaries and create impactful solutions that resonate with our clients and their audiences.  ",
    skills: [
      "Adobe Illustrator ",
      "Adobe Photoshop",
      "Design ",
      "Figma (Software)",
      "Generative AI",
      "Graphic Design Software",
      "Figma",
    ],
    refLink:"https://aspireup.com/",
    doc: "https://firebasestorage.googleapis.com/v0/b/buckoid-917cf.appspot.com/o/WhatsApp%20Image%202023-05-05%20at%2012.07.39%20AM.jpeg?alt=media&token=9f0e1648-568b-422d-bd0b-1f125f722245",
  },
  {
    id: 3,
    img: "https://media.licdn.com/dms/image/v2/D4D0BAQFh2QIjxFG9Ng/company-logo_200_200/company-logo_200_200/0/1697887695508?e=1733356800&v=beta&t=-ysxtmBVT_AkYRW9e4b04hh173BKRo-XZMwSXbn-bAo",
    role: "Graphic Designer",
    company: "TEDX",
    date: "August 2024 - Present",
    desc: "I'm excited to join Learn with TEDx as a Graphic Designer. Working remotely, I contribute to creating engaging visuals that align with TEDx's inspiring content, helping to enhance the brand's visual presence and impact.",
    skills: [
      "Web Design",
      "Typography",
      "User Experience (UX)",
      "User Interface Design",
      "Figma (Software)",
      "Graphic Design",
      "Generative AI",
      "Graphic Design Software",
      "Figma",
    ],
    refLink:"https://www.ted.com/about/programs-initiatives/tedx-program",
    doc: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/1691181448873.jpeg?alt=media&token=ee85eb8f-7247-43cd-9a1d-ce9f58ea62a6",
  },
  {
    id: 4,
    img: "https://media.licdn.com/dms/image/v2/C560BAQEdMLuTjTfRLQ/company-logo_100_100/company-logo_100_100/0/1631318881799?e=1733356800&v=beta&t=rgSj7WeNap8TvPWwJGpW2uyhMG_th674CNGIw7khooU",
    role: "Assistant Video Editor",
    company: "3DM Agency",
    date: "May 2024 - Present",
    desc: "I am excited to be a part of 3DM, a company renowned for its excellence in video production. This opportunity allows me to expand my skills and knowledge in video editing while delivering compelling content for our clients.",
    skills: [
      "Adobe Illustrator ",
      "Adobe Photoshop",
      "Design ",
      "After Effects",
      "Adobe Premiere pro",
      "Graphic Design Software",
    ],
    refLink:"https://3dm.agency/"
  },
  {
    id: 5,
    img: "https://media.licdn.com/dms/image/v2/C4E0BAQFt6KqGwvGCzg/company-logo_100_100/company-logo_100_100/0/1652805738201/instagram_logo?e=1733356800&v=beta&t=EUu0Dp3az0t270VRYP2V_TsbE0Ske0ZE9fRWjx1Qy2Q",
    role: "Content Creator ",
    company: "Instagram ",
    date: "March 2024 - Present",
    desc: "Experienced Content Creator specializing in video editing and VFX artistry. Bringing ideas to life through engaging visuals on Instagram. Let's captivate your audience together!",
    skills: [
      "Web Design",
      "Typography",
      "User Experience (UX)",
      "User Interface Design",
      "Figma (Software)",
      "Graphic Design",
    ],
    refLink:"https://www.instagram.com/editwithsanjay/"
  },
  
];

export const education = [
  {
    id: 0,
    img: "https://media.licdn.com/dms/image/v2/C560BAQFKt8O5GdaFjw/company-logo_100_100/company-logo_100_100/0/1680080095222/vnr_vignanajyothiinstituteofengineeringandtechnology_logo?e=1733356800&v=beta&t=8-fdoDo_WmVpR0RCtuAElsusUY1X6XQlhVFfHlgXQfE",
    school: "VNR Vignana Jyothi Institute of Engineering and Technology (VNRVJIET) ",
    date: "November 2022 - November 2026",
    grade: "8.50 CGPA - Present",
    desc: "I am currently pursuing a Bachelor's degree in Information Technology at VNR VJIET Engineering College, Telangana, with a GPA of 8.50. I've completed courses in Operating Systems, UI/UX Designing, Frontend Development, and Graphic Designing. I'm skilled in Python, HTML, CSS, JavaScript, and have hands-on experience with tools like Figma, Sketch, and Adobe Creative Suite. As a member of the Google Developer Student Clubs (GDSC), I've organized tech events and worked on exciting projects, enhancing my design and development skills.",
    degree: "Bachelor of Technology - BTech, Information Technology",
    
  },
  {
    id: 1,
    img: "https://media.licdn.com/dms/image/v2/C4E0BAQFGcnCCVep9bw/company-logo_200_200/company-logo_200_200/0/1630620788871/sri_chaitanya_college_of_education_logo?e=1733356800&v=beta&t=aqwDLtuHFQpOI6VdOiF9d2yTtZtATXc6wJe3xDWVeUI",
    school: "Sri Chaitanya College of Education",
    date: "November 2020 - August 2022",
    grade: "93.1%",
    desc: "I completed my class 12 high school education at Sri Chaitanya College of Education,Telangana,Miyapur where I studied Maths,Physics,Chemistry (MPC)",
    degree: "Intermediate Education , Maths,Physics,Chemistry (MPC)",
    
  },
  {
    id: 2,
    img: "https://media.licdn.com/dms/image/v2/C4E0BAQFGcnCCVep9bw/company-logo_200_200/company-logo_200_200/0/1630620788871/sri_chaitanya_college_of_education_logo?e=1733356800&v=beta&t=aqwDLtuHFQpOI6VdOiF9d2yTtZtATXc6wJe3xDWVeUI",
    school: "Sri Chaitanya School of Education",
    date: "November 2017 - August 2020",
    grade: "100%",
    desc: "I completed my class 10 education at Sri Chaitanya School, Telangana,Komapally.",
    degree: "Telangana State Board of Secondary Education",
  },
];

export const projects = [
  {
    id: 11,
    title: "Smart AI SaaS Website Design🤖",
    date: "April - May 2024",
    description:
      "Perfect for any SaaS business looking to showcase AI-based solutions with style and efficiency!",
    image:
      "https://cdn.cp.adobe.io/content/2/dcx/1ab31917-bac6-4893-998b-2064140af74d/rendition/rendition-a5b2ec1d-1c19-4219-b1b9-13cd23b63671-1.png/version/0/format/jpg/dimension/width/size/1200",
    category: "web app",
    github: "https://www.behance.net/attellisanjay",
    webapp: "https://www.behance.net/attellisanjay",
  },
  {
    id: 9,
    title: "Power Your Ride with Electric Mobility ⚡",
    date: "April - May 2024",
    description:
      "This E-bike UI design is modern, user-friendly,with bold visuals and clear calls-to-action.",
    image:
      "https://s3-alpha.figma.com/hub/file/5697392459/68ed2571-bf46-468a-9ae1-f7fe6ad51f08-cover.png",
    category: "web app",
    github: "https://www.behance.net/gallery/207119579/Power-Your-Ride-",
    webapp: "https://www.behance.net/gallery/207119579/Power-Your-Ride-",
  },
  {
    id: 0,
    title: "Elevate Your Health Care Projects! 🌟",
    date: "April - May 2024",
    description:
      "This website design provides a clean, professional layout that emphasizes excellence.",
    image:
      "https://s3-alpha.figma.com/hub/file/3777179428/ec15fef4-440a-4a4c-9ec0-78867c9cd57f-cover.png",
    tags: ["React Js", "MongoDb", "Node Js", "Express Js", "Redux"],
    category: "web app",
    github: "https://www.figma.com/community/file/1413038071885654752/health-care-website-design",
    webapp: "https://www.figma.com/community/file/1413038071885654752/health-care-website-design",
  },
  {
    id: 244,
    title: "SAFARI 3D(Parallax) Scroll Magic 🌟",
    date: "July - August 2024",
    description:
      "The Safari Parallax Scroll Effect adds depth to the users 3D illusion on the Safari Car website.",
    image:
      "https://s3-alpha.figma.com/hub/file/6566252714/d2f8f883-9319-4bbd-a32f-55530d7e62b5-cover.png",
    category: "machine learning",
    github: "https://www.behance.net/gallery/207210077/SAFARI-3D(Parallax)-Scroll-Magic-",
    webapp: "https://www.behance.net/gallery/207210077/SAFARI-3D(Parallax)-Scroll-Magic-",
    
  },
  {
    id: 245,
    title: "Cursor Vibrant Cursor Magic 🎨✨",
    date: "July - August 2024",
      description:
      "The cursor smoothly shifts between vibrant colors, creating an eye-catching user experience.",
    image:
      "https://s3-alpha.figma.com/hub/file/6566280426/e5e57ed2-5e84-4aa5-a9ff-faeed2158d0b-cover.png",
    category: "machine learning",
    github: "https://www.behance.net/gallery/207210677/Cursor-Vibrant-Cursor-Magic-",
    webapp: "https://www.behance.net/gallery/207210677/Cursor-Vibrant-Cursor-Magic-",
    
  },
  {
    id: 246,
    title: "Dynamic Parallax Swipe 🔥",
    date: "July - August 2024",
    description:
      "Experience captivating parallax effects that create a sense of depth and motion as users swipe through your content.",
    image:
      "https://s3-alpha.figma.com/hub/file/6566487085/dfd1dd64-7c76-487f-902e-72a960963e4f-cover.png",
    category: "machine learning",
    github: "https://www.behance.net/gallery/207212899/Dynamic-Parallax-Swipe-",
    webapp: "https://www.behance.net/gallery/207212899/Dynamic-Parallax-Swipe-",
    
  },
  {
    id: 247,
    title: "Fruit Fusion Slider 🍓",
    date: "July - August 2024",
    description:
      "Enjoy a fun and vibrant slider design featuring a variety of colorful fruits, adding a lively touch to your prototypes.",
    image:
      "https://s3-alpha.figma.com/hub/file/6566459326/040b7128-f9ea-4956-810b-4f521684f109-cover.png",
    category: "machine learning",
    github: "https://www.behance.net/gallery/207212639/How-about-Fruit-Fusion-Slider-",
    webapp: "https://www.behance.net/gallery/207212639/How-about-Fruit-Fusion-Slider-",
    
  },

  {
    id: 3,
    title: "Nike App UI Design 👟",
    date: "June - July 2024",
    description:
      "The app welcomes users with a smooth and engaging onboarding process, app right from the start.",
    image:
      "https://mir-s3-cdn-cf.behance.net/project_modules/fs/34b64e207128873.66d8459957379.png",
    category: "android app",
    github: "https://www.behance.net/gallery/207128873/Nike-App-UI-Design",
    webapp: "https://www.behance.net/gallery/207128873/Nike-App-UI-Design",
  },
  {
    id: 10,
    title: "SNACKFLEET 🍔⚡",
    date: "June - July 2024",
    description:
      "A 10-minute food delivery app sounds convenient, efficient, and incredibly fast.",
    image:
      "https://s3-alpha.figma.com/hub/file/6158549733/ca02506c-acc3-4709-b0cd-5ad50c1fd20d-cover.png",
    category: "android app",
    github: "https://www.behance.net/gallery/207129449/SNACKFLEET(10-minute-food-delivery-app)",
    webapp: "https://www.behance.net/gallery/207129449/SNACKFLEET(10-minute-food-delivery-app)",
  },
  {
    id: 100,
    title: "Spatial Flow UI 🌌",
    date: "June - July 2024",
    description:
      "Experience smooth and visually stunning transitions between UI elements, enhancing the overall user journey.",
    image:
      "https://s3-alpha.figma.com/hub/file/6566363107/3a98bf09-5f80-465d-a9b5-7141d72f3621-cover.png",
    category: "android app",
    github: "https://www.behance.net/gallery/207212061/Spatial-Flow-UI-",
    webapp: "https://www.behance.net/gallery/207212061/Spatial-Flow-UI-",
  },
  {
    id: 4,
    title: "🎬 Netflix Landing Page UX Case Study ",
    date: "July - August 2024",
    description:
      "This case study explores the UX design improvements for Netflix's landing page, intuitive navigation.",
    image:
      "https://s3-alpha.figma.com/hub/file/6567184284/a0c1b73e-cb1f-4759-ac53-c26baead9226-cover.png",
    category: "caseStudy",
    github: "https://www.behance.net/gallery/207213825/-Netflix-Landing-Page-UX-Case-Study-",
    webapp: "https://www.behance.net/gallery/207213825/-Netflix-Landing-Page-UX-Case-Study-",
  },

  {
    id: 5,
    title: "🎭 BookMyShow Deck UX Case Study",
    date: "July - August 2024",
    description:
      "This Behance project presents a streamlined redesign of the BookMyShow interface.",
    image:
      "https://s3-figma-hubfile-images-production.figma.com/hub/file/carousel/img/e01cac4c301735006c080e6721c8f02357de3702/06945e89bee670347a546f346a1070960b7bcf86",
    category: "caseStudy",
    github: "https://www.behance.net/gallery/207213195/Showtime-Simplified-BookMyShow-Deck-",
    webapp: "https://www.behance.net/gallery/207213195/Showtime-Simplified-BookMyShow-Deck-",
  },
  {
    id: 405,
    title: "Spotify Case Study 🎵✨",
    date: "July - August 2024",
    description:
      " Unveiling the Case Study 🎧✨: Dive into our first captivating case study focused on Spotify.",
    image:
      "https://miro.medium.com/v2/resize:fit:1400/1*VnWaQTFC6AdnbggQNLc37g.png",
    category: "caseStudy",
    github: "https://www.behance.net/gallery/207214585/Spotify-Case-Study-",
    webapp: "https://www.behance.net/gallery/207214585/Spotify-Case-Study-",
  },
  {
    id: 405,
    title: "Travel App UX Case Study 🌍✈️",
    date: "July - August 2024",
    description:
      " 📱✨Discover how we designed an intuitive and engaging experience for globetrotters.",
    image:
      "https://s3-alpha.figma.com/hub/file/6568072089/51c1d877-fd0b-40b0-ba81-123cb393af39-cover.png",
    category: "caseStudy",
    github: "https://www.behance.net/gallery/207214863/Explore-Go-Travel-App-UX-Case-Study-",
    webapp: "https://www.behance.net/gallery/207214863/Explore-Go-Travel-App-UX-Case-Study-",
  },
  
];

